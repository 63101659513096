var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "60%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("进入教室")])]
      ),
      _vm.source === "tms"
        ? _c("el-row", { staticClass: "classList" }, [
            _c("p", [
              _vm._v("你可以选择使用Chrome浏览器或云端课堂客户端进入教室"),
            ]),
            _c(
              "p",
              [
                _vm._v("参加码："),
                _c("span", {
                  staticClass: "code",
                  domProps: { textContent: _vm._s(_vm.student_code) },
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.student_code,
                        expression: "student_code",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.copy,
                        expression: "copy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error",
                      },
                    ],
                    staticStyle: { "margin-left": "20px" },
                    attrs: { type: "warning", size: "mini", plain: "" },
                  },
                  [_vm._v("点击复制")]
                ),
                _c(
                  "el-row",
                  { staticClass: "img-container" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "img-item",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToLive(0)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "chrome",
                          attrs: {
                            src: require("../../assets/images/software_img01.png"),
                            alt: "Chrome进入教室",
                          },
                        }),
                        _c("p", [_vm._v("Chrome进入教室")]),
                      ]
                    ),
                    _c(
                      "el-row",
                      {
                        staticClass: "img-item",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToLive(1)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/software_img12.png"),
                            alt: "客户端进入教室",
                          },
                        }),
                        _c("p", [_vm._v("客户端进入教室")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm.source === "校管家V"
        ? _c("el-row", { staticClass: "classList" }, [
            _c("p", [
              _vm._v(
                "使用参加码，通过下面的直播间入口或者下载移动端APP进入教室"
              ),
            ]),
            _c(
              "p",
              [
                _vm._v("参加码："),
                _c("span", {
                  staticClass: "code",
                  domProps: { textContent: _vm._s(_vm.student_code) },
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.student_code,
                        expression: "student_code",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.copy,
                        expression: "copy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error",
                      },
                    ],
                    staticStyle: { "margin-left": "20px" },
                    attrs: { type: "warning", size: "mini", plain: "" },
                  },
                  [_vm._v("点击复制")]
                ),
              ],
              1
            ),
            _c(
              "p",
              [
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "small", type: "warning" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goToLive(0)
                      },
                    },
                  },
                  [_vm._v("点击进入直播间")]
                ),
                _c(
                  "a",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "#e6a23c",
                      "margin-left": "15px",
                    },
                    attrs: {
                      href: "https://www.baijiayun.com/classroomdown/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("下载云端课堂")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }