<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    append-to-body
    width="60%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>进入教室</span>
    </div>
    <!-- source:{{source}} -->
    <el-row class="classList" v-if="source === 'tms'">
      <p>你可以选择使用Chrome浏览器或云端课堂客户端进入教室</p>
      <p>参加码：<span class="code" v-text="student_code" ></span>
        <el-button
          type="warning"
          size="mini"
          plain
          v-clipboard:copy="student_code"
          v-clipboard:success="copy"
          v-clipboard:error="onError"
          style="margin-left: 20px;"
        >点击复制</el-button>
        <el-row class="img-container">
          <el-row class="img-item" @click.native="goToLive(0)">
            <img src="../../assets/images/software_img01.png" alt="Chrome进入教室" class="chrome">
            <p>Chrome进入教室</p>
          </el-row>
          <el-row class="img-item" @click.native="goToLive(1)">
            <img src="../../assets/images/software_img12.png" alt="客户端进入教室">
            <p>客户端进入教室</p>
          </el-row>
        </el-row>
      </p>
    </el-row>

    <el-row v-else-if="source === '校管家V'" class="classList">
      <p>使用参加码，通过下面的直播间入口或者下载移动端APP进入教室</p>
      <p>参加码：<span class="code" v-text="student_code" ></span>
        <el-button
          type="warning"
          size="mini"
          plain
          v-clipboard:copy="student_code"
          v-clipboard:success="copy"
          v-clipboard:error="onError"
          style="margin-left: 20px;"
        >点击复制</el-button>
      </p>
      <p>
        <el-button plain size="small" @click.native="goToLive(0)" type="warning">点击进入直播间</el-button>
        <a href="https://www.baijiayun.com/classroomdown/" target="_blank" style="font-size:14px;color: #e6a23c;margin-left:15px;">下载云端课堂</a>
      </p>
      
    </el-row>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    liveId: {
      default: "",
      type: String
    },
  },
  data () {
    return {
      student_code: '',
      source: '',
      webUrl: '',
      bjyUrl: 'https://www.baijiayun.com/entry'
    }
  },
  methods: {
    openDialog () {
      fGet("/student/class/live/getLiveRoomtnById", {
        liveRoomId: this.liveId
      }).then(res => {
        if (res.state === "success") {
          this.source = res.body.source
          this.student_code = res.body.student_code
          if (this.source === 'tms') {
            fGet("/student/class/live/getWebLiveEnter", {
              liveRoomId: this.liveId
            }).then(res => {
              if (res.state === "success") {
                this.student_code = res.body.student_code
                this.webUrl = res.body.webUrl
              }
            })
          }
        }
      })
    },

    copy (e) {
      window.$msg('复制成功')
    },
    onError (e) {
      window.$msg('复制失败')
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 进入直播间
    goToLive (type) {
      if (type === 0) {
        if (this.source === 'tms') {
          window.open(this.webUrl)
        } else {
          window.open(this.bjyUrl)
        }
      } else {
        let client_url = 'baijiacloud://urlpath=' + this.webUrl + '&token=token&ts=ts'
        window.open(client_url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
.classList {
  width: 60%;
  margin: 0 auto 0;
  p {
    color: #333333;
    font-size: 16px;
    line-height: 45px;
    .code {
      font-weight: 700;
      color: #0099FF;
    }
  }
}

.img-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .img-item {
    // width: 74px;
    cursor: pointer;
    margin-right: 65px;
    img {
      width: 74px;
      height: 74px;
    }
    p {
      font-size: 15px;
      color: #9B9B9B;
    }
  }
}

.chrome {
  width: 90px !important;
}
</style>
